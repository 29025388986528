import React from 'react';

const SalesforceAutomation = ({ className }: { className?: string }) => (
  <svg
    className={className}
    width="49"
    height="48"
    viewBox="0 0 49 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M47.4733 28.9338H21.467C21.3008 28.9338 21.141 28.8673 21.0234 28.7488C20.9059 28.6304 20.8401 28.4699 20.8401 28.3024C20.8463 28.1369 20.9142 27.9798 21.0305 27.8627C21.1467 27.7456 21.3027 27.6772 21.467 27.671H46.7459V7.26287H9.85573V10.9252C9.85573 11.0927 9.78962 11.2533 9.67205 11.3717C9.55447 11.4901 9.39505 11.5567 9.22877 11.5567C9.06249 11.5567 8.90306 11.4901 8.78549 11.3717C8.66791 11.2533 8.60181 11.0927 8.60181 10.9252V6.63144C8.60181 6.46397 8.66791 6.30341 8.78549 6.18499C8.90306 6.06657 9.06249 6 9.22877 6H47.3729C47.5392 6 47.6986 6.06657 47.8162 6.18499C47.9338 6.30341 47.9999 6.46397 47.9999 6.63144V28.3024C48.0018 28.4537 47.9496 28.6007 47.8529 28.7166C47.7563 28.8325 47.6217 28.9096 47.4733 28.9338Z"
      fill="#475467"
    />
    <path
      d="M28.4099 47.9862C28.2436 47.9862 28.0842 47.9196 27.9666 47.8012C27.8491 47.6828 27.783 47.5222 27.783 47.3548V28.2096C27.783 28.0421 27.8491 27.8815 27.9666 27.7631C28.0842 27.6447 28.2436 27.5781 28.4099 27.5781C28.5742 27.5844 28.7302 27.653 28.8465 27.7701C28.9627 27.8871 29.0307 28.0441 29.0369 28.2096V47.3548C29.0369 47.5222 28.9711 47.6828 28.8535 47.8012C28.7359 47.9196 28.5762 47.9862 28.4099 47.9862Z"
      fill="#475467"
    />
    <path
      d="M34.0169 47.9838C33.9108 47.9823 33.8068 47.9538 33.7147 47.9007C33.6226 47.8477 33.5452 47.772 33.49 47.6808L28.4743 39.0175L23.4586 47.6808C23.4175 47.7523 23.3626 47.8145 23.2967 47.8637C23.2308 47.913 23.1555 47.9481 23.0757 47.967C22.9958 47.986 22.9131 47.9883 22.8323 47.9738C22.7515 47.9593 22.6745 47.9284 22.6061 47.8829C22.4637 47.7985 22.3603 47.661 22.318 47.5002C22.2757 47.3394 22.2982 47.1683 22.3804 47.024L27.998 37.4515C28.0588 37.3598 28.1407 37.2843 28.2368 37.2315C28.3329 37.1787 28.4402 37.1503 28.5496 37.1484C28.6552 37.1522 28.7585 37.1817 28.8503 37.2345C28.942 37.2873 29.0193 37.3618 29.0762 37.4515L34.6937 47.024C34.776 47.1683 34.7984 47.3394 34.7562 47.5002C34.7139 47.661 34.6104 47.7985 34.4681 47.8829C34.4064 47.9349 34.3333 47.9712 34.2547 47.9888C34.1762 48.0064 34.0946 48.0047 34.0169 47.9838Z"
      fill="#475467"
    />
    <path
      d="M8.57619 22.2379C8.10312 22.2379 7.63472 22.1437 7.19798 21.9606C6.76124 21.7775 6.36465 21.5092 6.03131 21.1711C5.69797 20.8331 5.43431 20.4319 5.25557 19.9907C5.07682 19.5496 4.98653 19.0773 4.98984 18.6008V16.5297C4.94825 16.0283 5.01033 15.5237 5.1723 15.0478C5.33427 14.5718 5.59266 14.1348 5.93089 13.7645C6.26913 13.3942 6.67989 13.0987 7.13736 12.8965C7.59483 12.6943 8.08916 12.5898 8.58874 12.5898C9.08832 12.5898 9.58234 12.6943 10.0398 12.8965C10.4973 13.0987 10.908 13.3942 11.2463 13.7645C11.5845 14.1348 11.8429 14.5718 12.0049 15.0478C12.1668 15.5237 12.2289 16.0283 12.1873 16.5297V18.6008C12.1907 19.0794 12.0995 19.5538 11.9192 19.9966C11.7389 20.4394 11.473 20.8417 11.137 21.1801C10.801 21.5185 10.4017 21.7864 9.96205 21.968C9.5224 22.1496 9.05136 22.2413 8.57619 22.2379ZM8.57619 14.1555C7.95533 14.1621 7.3622 14.4153 6.92552 14.8598C6.48884 15.3044 6.24373 15.9044 6.24376 16.5297V18.6008C6.20976 18.9315 6.24519 19.2656 6.34754 19.5816C6.44989 19.8976 6.61693 20.1885 6.83797 20.4353C7.059 20.6822 7.32897 20.8796 7.63054 21.0147C7.93211 21.1497 8.25868 21.2195 8.58874 21.2195C8.91879 21.2195 9.24506 21.1497 9.54663 21.0147C9.8482 20.8796 10.1182 20.6822 10.3392 20.4353C10.5602 20.1885 10.7276 19.8976 10.8299 19.5816C10.9323 19.2656 10.9674 18.9315 10.9334 18.6008V16.5297C10.9334 15.9 10.6852 15.2961 10.2431 14.8509C9.80099 14.4057 9.2014 14.1555 8.57619 14.1555Z"
      fill="#475467"
    />
    <path
      d="M12.5109 47.9775H4.83707C4.67079 47.9775 4.51137 47.9109 4.39379 47.7925C4.27621 47.6741 4.21011 47.5135 4.21011 47.3461V37.8745C3.38556 37.8064 2.6113 37.4473 2.02401 36.8604C1.43672 36.2736 1.07406 35.4966 1 34.6667V28.605C1.00663 27.0084 1.64092 25.4795 2.76424 24.3529C3.88757 23.2262 5.40847 22.5937 6.99377 22.5938H22.0408C22.2199 22.595 22.3911 22.6677 22.5172 22.7959C22.5769 22.8642 22.6213 22.9445 22.6473 23.0317C22.6732 23.1189 22.6803 23.2107 22.6678 23.3009C22.6007 24.5 22.0942 25.6314 21.2464 26.4759C20.3986 27.3205 19.2695 27.8181 18.0782 27.8725H13.0625V47.4219C13.0465 47.5637 12.9833 47.6957 12.8832 47.7967C12.783 47.8976 12.6517 47.9613 12.5109 47.9775ZM5.46403 46.7146H11.8839V27.2663C11.8892 27.099 11.9513 26.9386 12.0597 26.8117C12.1186 26.7524 12.1892 26.706 12.2669 26.6756C12.3446 26.6451 12.4276 26.6313 12.5109 26.6349H18.1284C18.8938 26.6093 19.628 26.3231 20.2111 25.8231C20.7941 25.3231 21.1915 24.6388 21.3385 23.8819H6.99377C5.74103 23.8819 4.53928 24.3813 3.65111 25.271C2.76294 26.1608 2.26055 27.3687 2.25392 28.6303V34.6667C2.31674 35.1672 2.54297 35.6323 2.8971 35.989C3.25124 36.3456 3.71324 36.5736 4.21011 36.6369V29.0597C4.21011 28.8922 4.27621 28.7316 4.39379 28.6132C4.51137 28.4948 4.67079 28.4282 4.83707 28.4282C5.00137 28.4345 5.15705 28.5029 5.27331 28.62C5.38957 28.7371 5.45782 28.8942 5.46403 29.0597V46.7146Z"
      fill="#475467"
    />
    <path
      d="M8.58228 47.9815C8.416 47.9815 8.25658 47.9149 8.139 47.7965C8.02142 47.6781 7.95532 47.5175 7.95532 47.35V36.565C7.95532 36.3976 8.02142 36.237 8.139 36.1186C8.25658 36.0002 8.416 35.9336 8.58228 35.9336C8.74856 35.9336 8.90798 36.0002 9.02556 36.1186C9.14314 36.237 9.20924 36.3976 9.20924 36.565V47.35C9.20924 47.5175 9.14314 47.6781 9.02556 47.7965C8.90798 47.9149 8.74856 47.9815 8.58228 47.9815Z"
      fill="#475467"
    />
    <path
      d="M40.3665 16.7684C40.2113 16.7385 40.0732 16.6507 39.9795 16.5226C39.8859 16.3945 39.8436 16.2356 39.8613 16.0776L39.9715 15.5072L39.4054 15.3963C39.239 15.3807 39.0853 15.2992 38.9785 15.1697C38.8718 15.0402 38.8207 14.8734 38.8361 14.7058C38.8516 14.5382 38.9325 14.3837 39.0611 14.2762C39.1897 14.1687 39.3553 14.1169 39.5217 14.1325L40.7272 14.2402C40.8088 14.2455 40.8886 14.2675 40.9614 14.3048C41.0343 14.3421 41.0986 14.3938 41.1509 14.4571C41.2033 14.5203 41.2423 14.5935 41.2656 14.6724C41.2889 14.7513 41.296 14.8343 41.2865 14.916L41.204 16.1349C41.195 16.2177 41.17 16.2978 41.1301 16.3707C41.0902 16.4437 41.0365 16.508 40.9719 16.5599C40.9072 16.6118 40.833 16.6503 40.7535 16.6732C40.674 16.6961 40.5907 16.703 40.5085 16.6934L40.3665 16.7684Z"
      fill="#475467"
    />
    <path
      d="M23.774 22.4453C23.6643 22.446 23.5555 22.4247 23.454 22.3826L21.6149 20.5298C21.5554 20.4738 21.508 20.4063 21.4752 20.3313C21.4424 20.2563 21.425 20.1754 21.4241 20.0934C21.4233 20.0115 21.4388 19.9301 21.47 19.8545C21.5012 19.7788 21.5474 19.7102 21.6056 19.653C21.6637 19.5928 21.7331 19.5448 21.8097 19.5119C21.8864 19.479 21.9688 19.4619 22.0522 19.4615C22.1355 19.461 22.2182 19.4773 22.2952 19.5094C22.3722 19.5415 22.442 19.5887 22.5007 19.6483L23.9345 21.0874L28.3573 17.5029C28.4684 17.4206 28.6025 17.3759 28.7403 17.3752C28.8782 17.3745 29.0125 17.4178 29.1244 17.4989L32.2647 19.89L40.1907 14.5734C40.3277 14.4824 40.4945 14.4492 40.6555 14.4808C40.8166 14.5123 40.9591 14.6062 41.0522 14.7423C41.1006 14.8118 41.1344 14.8904 41.152 14.9734C41.1695 15.0564 41.1705 15.1423 41.1544 15.2256C41.1383 15.3089 41.1054 15.388 41.0582 15.4584C41.0111 15.5287 40.9504 15.5888 40.8797 15.6349L32.5762 21.1861C32.4689 21.2663 32.3384 21.3083 32.2048 21.3054C32.0712 21.3025 31.9424 21.2549 31.8386 21.1702L28.7674 18.8184L24.2905 22.418C24.2113 22.4592 24.1243 22.483 24.0353 22.4878C23.9462 22.4925 23.8571 22.478 23.774 22.4453Z"
      fill="#475467"
    />
  </svg>
);

export default SalesforceAutomation;
